import React, { useState } from 'react'
import { Box, List, Typography } from '@mui/material'
import PickEmployee from 'Meetings/PickEmployee'
import { useRole } from 'Utils/Hooks/useRole'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import { useMeetings } from 'Meetings/useMeetings'
import TimePicker from 'Meetings/TimePicker'
import ChangeMeetingType from 'Components/ChangeMeetingType'

interface IRebookMeetingProps {
  bookedMeeting: IMeeting
  newMeeting: IMeeting
  handleChangeTimeSlot: (slot: IMeeting) => void
  handleSetStep: (step: number) => void
}

const RebookMeetingPickTime = (
  {
    bookedMeeting,
    newMeeting,
    handleSetStep,
    handleChangeTimeSlot
  }: React.PropsWithChildren<IRebookMeetingProps>
) => {
  const {
    advisorHeading,
    showTimesLabel,
    pickDateAndTime,
    confirmMeetingRescheduleBody,
  } = useEpiContent<IContent>()
  const isTIA = useRole()
  const { latestBookedMeeting, meetingSlotsByLatestEmployee } = useMeetings()

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    meetingSlotsByLatestEmployee.length > 0
      ? latestBookedMeeting?.employee?.id
      : 'all'
  )

  return (
    <>
      {!isTIA ? (
        <Box sx={{ paddingBottom: 3 }}>
          <>
            <Typography
              variant="body1"
              fontWeight={'bold'}
              sx={{ display: 'inline' }}
            >
              {advisorHeading}
            </Typography>
            &nbsp;
            <Typography variant="body1" sx={{ display: 'inline' }}>
              {bookedMeeting.employee.fullname}
            </Typography>
          </>
        </Box>
      ) : (
        latestBookedMeeting?.employee !== undefined &&
        meetingSlotsByLatestEmployee.length > 0 && (
          <>
            <Typography component="h2" variant="h5">
              {showTimesLabel}
            </Typography>
            <PickEmployee
              latestBookedMeeting={latestBookedMeeting}
              employeeId={selectedEmployeeId}
              setEmployee={setSelectedEmployeeId}
            />
          </>
        )
      )}

      <Typography variant="h5" component="h2" sx={{ paddingBottom: 4 }}>
        {pickDateAndTime}
      </Typography>

      <TimePicker
        meeting={newMeeting}
        setMeeting={handleChangeTimeSlot}
        employeeId={selectedEmployeeId}
        errorMessage={''}
      />

      <Typography variant="body1" sx={{ paddingBottom: 4 }}>
        {confirmMeetingRescheduleBody}
      </Typography>

      <List sx={{ paddingBottom: 4 }}>
        <ChangeMeetingType
          handleSetStep={handleSetStep}
          meeting={newMeeting.meetingLocationType ? newMeeting : bookedMeeting}
        />
      </List>
    </>
  )
}

export default RebookMeetingPickTime
