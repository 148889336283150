import React, { FormEvent } from 'react'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'

interface IPickEmployeeProps {
  setEmployee: (employeeId: string) => void
  employeeId: string
  latestBookedMeeting: IMeeting
}

const PickMeetingType = (
  {
    setEmployee,
    employeeId,
    latestBookedMeeting
  }: React.PropsWithChildren<IPickEmployeeProps>
) => {
  const { allEmployeesLabel, latestEmployeeLabel } = useEpiContent<IContent>()
  const selectEmployee = React.useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setEmployee(e.currentTarget.value)
    },
    [setEmployee]
  )

  return (
    <FormControl>
      <RadioGroup>
        <FormControlLabel
          name="allEmployees"
          checked={employeeId === 'all'}
          value="all"
          control={<Radio />}
          label={allEmployeesLabel}
          onChange={selectEmployee}
        />
        <FormControlLabel
          name="employeeId"
          value={latestBookedMeeting?.employee?.id}
          control={<Radio />}
          checked={employeeId === latestBookedMeeting?.employee?.id}
          label={`${latestBookedMeeting?.employee?.fullname} (${latestEmployeeLabel})`}
          data-testid="setEmployeeRadio"
          onChange={selectEmployee}
        />
      </RadioGroup>
    </FormControl>
  )
}

export default PickMeetingType
