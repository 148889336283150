import { combineReducers, configureStore } from '@reduxjs/toolkit'
import confirmationModalSlice from 'Meetings/ConfirmationModal/confirmationModalSlice'
import meetingEventTypeSlice from 'Meetings/meetingEventTypeSlice'
import { IMeetingsState } from 'Meetings/BookMeeting/BookMeeting.types'

import { baseApi } from '../Services/API/baseApi'

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  ['confirmationModal']: confirmationModalSlice,
  ['meetingEventType']: meetingEventTypeSlice,
})

const storeConfig = (preloadedState?: RootState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware().concat([baseApi.middleware]),
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  })

const store = storeConfig()

export interface IApplicationState {
  meetings: IMeetingsState
}

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export default store
