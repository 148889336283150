import { Box, Button } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { HTMLMapper } from '@trr/html-mapper'

export interface IMeetingPurposeStepProps {
  handleNextButtonClick: () => void
}

const StartStep = ({ handleNextButtonClick }: IMeetingPurposeStepProps) => {
  const { introBodyHTML, bookAMeetingButtonText } = useEpiContent<IContent>()

  return (
    <>
      <Box sx={{ paddingBottom: 5 }}>
        <HTMLMapper body={introBodyHTML} mediaUrl="" />
      </Box>
      <Button onClick={() => handleNextButtonClick()} data-testid="book-button">
        {bookAMeetingButtonText}
      </Button>
    </>
  )
}

export default StartStep
