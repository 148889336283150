import { baseApi } from '../baseApi'

import { IAdvisorResponse } from './advisor.types'

export const advisorApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAdvisor: builder.query<IAdvisorResponse, string>({
      query: (sub: string) => ({
        url: `arende/klient/${sub}/radgivare`,
      }),
      providesTags: ['Advisor'],
    }),
  }),
})
