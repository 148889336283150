import { either, isNil, isEmpty } from 'ramda'

type MapToOutput<I, U = I> = (v: I) => U

export const isLinkExternal: MapToOutput<string, boolean> = (href: string) =>
  href.substr(0, 4) === 'http' || href.startsWith('mailto')

export const checkNullUndefined: MapToOutput<unknown, boolean> = either(
  isEmpty,
  isNil
)

export const scrollToTop = (): void => window.scrollTo(0, 0)
