import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'Store/configureStore'

import { meetingsApi, cacheKeys } from './meetings.api'

export const selectBookedMeetingsState = createSelector(
  (state: RootState) => meetingsApi.endpoints.getBookedMeetings.select()(state),
  (bookedMeetingsState) => bookedMeetingsState
)

export const selectBookedMeetings = createSelector(
  selectBookedMeetingsState,
  (bookedMeetings) => bookedMeetings?.data?.meetings ?? []
)

export const selectBookedMeetingsInTheFuture = createSelector(
  selectBookedMeetingsState,
  (bookedMeetings) =>
    bookedMeetings?.data?.meetings
      .filter((meeting) => {
        // Filter out meetings in the past

        const now = new Date(Date.now())
        const fifteenMinutesAgo = now.setMinutes(now.getMinutes() - 15)
        // Filter out meetings in the past
        return (
          meeting && new Date(meeting.startTime).getTime() > fifteenMinutesAgo
        )
      })
      ?.sort((a, b) => a.startTime.localeCompare(b.startTime)) ?? []
)

export const selectLatestBookedMeeting = createSelector(
  selectBookedMeetingsState,
  (bookedMeetings) => bookedMeetings?.data?.meetings[0]
)

export const selectAvailableSlotsState = createSelector(
  (state: RootState) => meetingsApi.endpoints.getAvailableSlots.select()(state),
  (availableSlotsState) => availableSlotsState
)

export const selectAvailableSlots = createSelector(
  selectAvailableSlotsState,
  (availableSlots) =>
    availableSlots?.data?.timeslots?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.startTime === value.startTime)
    ) ?? []
)

export const selectAvailableSlotsByLatestEmployee = createSelector(
  selectAvailableSlotsState,
  selectLatestBookedMeeting,
  (availableSlots, latestBookedMeeting) =>
    availableSlots.data?.timeslots.filter(
      (timeslot) => timeslot?.ownerId === latestBookedMeeting?.employee?.id
    ) ?? []
)

export const selectBookMeetingStatus = createSelector(
  meetingsApi.endpoints.bookMeeting.select(cacheKeys.bookMeeting),
  (bookMeetingState) => bookMeetingState.status
)

export const selectRebookMeetingStatus = createSelector(
  meetingsApi.endpoints.rebookMeeting.select(cacheKeys.rebookMeeting),
  (rebookMeetingState) => rebookMeetingState.status
)

export const selectCancelMeetingStatus = createSelector(
  meetingsApi.endpoints.cancelMeeting.select(cacheKeys.cancelMeeting),
  (cancelMeetingState) => cancelMeetingState.status
)
