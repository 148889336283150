import { Box, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import PickEmployee from 'Meetings/PickEmployee'
import TimePicker from 'Meetings/TimePicker'
import { useMeetings } from 'Meetings/useMeetings'

import { useRole } from 'Utils/Hooks/useRole'

export interface IPickTimeStepProps {
  meeting: IMeeting
  employeeId: string
  handleChangeEmployee: (employeeId: string) => void
  handleChangeTimeSlot: (meeting: IMeeting) => void
  handleClearMeeting: () => void
  errorMessage: string
}
const PickTimeStep = ({
  meeting,
  employeeId,
  handleChangeEmployee,
  handleChangeTimeSlot,
  errorMessage,
}: React.PropsWithChildren<IPickTimeStepProps>) => {
  const content = useEpiContent<IContent>()
  const isTIA = useRole()
  const { latestBookedMeeting, meetingSlotsByLatestEmployee, advisor } =
    useMeetings()

  return (
    <Box sx={{ paddingBottom: 3 }}>
      {advisor && (
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="body1">
            <Typography
              component={'span'}
              fontWeight={700}
              display={'inline-block'}
            >
              {content.advisorHeading}
            </Typography>
            {` ${advisor.radgivare?.fornamn} ${advisor.radgivare?.efternamn}`}
          </Typography>
        </Box>
      )}
      {isTIA &&
        latestBookedMeeting?.employee !== undefined &&
        meetingSlotsByLatestEmployee.length > 0 && (
          <>
            <Typography component="h2" variant="h5">
              {content.showTimesLabel}
            </Typography>
            <PickEmployee
              latestBookedMeeting={latestBookedMeeting}
              employeeId={employeeId}
              setEmployee={handleChangeEmployee}
            />
          </>
        )}
      <Typography component="h2" variant="h5" sx={{ marginBottom: 4 }}>
        {content.pickDateAndTime}
      </Typography>
      <TimePicker
        errorMessage={errorMessage}
        meeting={meeting}
        employeeId={employeeId}
        setMeeting={handleChangeTimeSlot}
      />
    </Box>
  )
}
export default PickTimeStep
