import React, { ChangeEvent } from 'react'
import { Box, FormHelperText, TextField, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'

interface IMeetingPurposeProps {
  message: string
  handleSetMessage: (message: string) => void
  errorMessage: string
}

const MeetingPurpose = (
  {
    message,
    handleSetMessage,
    errorMessage
  }: React.PropsWithChildren<IMeetingPurposeProps>
) => {
  const { introMeetingPurpose, textareaLabelMeetingPurpose } =
    useEpiContent<IContent>()

  const handleTextChange = React.useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target
      handleSetMessage(value)
    },
    [handleSetMessage]
  )

  return (
    <>
      <Typography variant="body1" sx={{ paddingBottom: 4 }}>
        {introMeetingPurpose}
      </Typography>
      <Box sx={{ paddingBottom: 2 }}>
        <TextField
          sx={{ width: '100%' }}
          multiline
          rows={4}
          label={textareaLabelMeetingPurpose}
          placeholder="Skriv här..."
          onChange={handleTextChange}
          value={message}
          data-testid="booking-textarea"
          id="booking-textarea"
        />
        <FormHelperText error sx={{ margin: 0 }}>
          {errorMessage}
        </FormHelperText>
      </Box>
    </>
  )
}

export default MeetingPurpose
