import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'Store/configureStore'

export interface MeetingEventTypeState {
  meetingEventType: string
}

const initialState: MeetingEventTypeState = {
  meetingEventType: '',
}

export const meetingEventTypeSlice = createSlice({
  name: 'meetingEventType',
  initialState,
  reducers: {
    setMeetingEventType: (state, action: PayloadAction<string>) => {
      state.meetingEventType = action.payload
    },
  },
})

export const { setMeetingEventType } = meetingEventTypeSlice.actions

export const selectMeetingEventType = (state: RootState) =>
  state.meetingEventType

export default meetingEventTypeSlice.reducer
