import { useCallback } from 'react'
import { useAppSelector } from 'Utils/Hooks/useAppSelector'
import { useAppDispatch } from 'Utils/Hooks/useAppDispatch'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import { advisorApi } from 'Services/API/advisor/advisor.api'
import {
  selectAdvisor,
  selectAdvisorState,
} from 'Services/API/advisor/advisor.selectors'
import { useAuthentication } from '@trr/app-shell-data'

import { cacheKeys, meetingsApi } from '../Services/API/meetings/meetings.api'
import {
  selectBookedMeetingsInTheFuture,
  selectBookedMeetingsState,
  selectAvailableSlots,
  selectAvailableSlotsState,
  selectLatestBookedMeeting,
  selectAvailableSlotsByLatestEmployee,
} from '../Services/API/meetings/meetings.selectors'

import {
  closeModal,
  showModal,
  selectModal,
} from './ConfirmationModal/confirmationModalSlice'
import { IMeetingsState } from './BookMeeting/BookMeeting.types'
import { selectMeetingEventType } from './meetingEventTypeSlice'

export interface IUseMeetings extends IMeetingsState {
  getBookedMeetings: () => void
  getMeetingSlots: () => void
  sendMeetingCancellation: (meetingId: string, reason: string) => void
  sendMeetingRebooking: (
    meetingId: string,
    timeSlot: IMeeting,
    meetingLocationType?: string
  ) => void
  sendMeetingBooking: (meeting: IMeeting, description: string) => void
  toggleConfirmationModal: (isToggled: boolean) => void
  getAdvisor: () => void
}

export const useMeetings = (): IUseMeetings => {
  const initialState: IMeetingsState = {
    meetingsLoading: false,
    meetingsLoaded: false,
    bookedMeetingsLoading: false,
    bookedMeetingsLoaded: false,
    sendBookingLoading: false,
    sendBookingLoaded: false,
    meetingSlots: [],
    bookedMeetings: [],
    latestBookedMeeting: {
      startTime: '',
      endTime: '',
      id: '',
      employee: {
        id: '',
        fullname: '',
        userPrincipalName: '',
      },
    },
    meetingSlotsByLatestEmployee: [],

    bookingStatus: '',
    cancellationStatus: '',
    meetingEventType: '',
    rebookingStatus: '',
    isToggledConfirmationModal: false,
    advisorLoading: false,
    advisorLoaded: false,
    advisor: null,
  }

  const dispatch = useAppDispatch()
  const { sub } = useAuthentication()

  const [triggerGetBookedMeetingsQuery] =
    meetingsApi.useLazyGetBookedMeetingsQuery()
  const [triggerAvailableSlotsQuery] =
    meetingsApi.useLazyGetAvailableSlotsQuery()

  const [bookMeeting] = meetingsApi.useBookMeetingMutation({
    fixedCacheKey: cacheKeys.bookMeeting,
  })
  const [cancelMeeting] = meetingsApi.useCancelMeetingMutation({
    fixedCacheKey: cacheKeys.cancelMeeting,
  })
  const [rebookMeeting] = meetingsApi.useRebookMeetingMutation({
    fixedCacheKey: cacheKeys.rebookMeeting,
  })

  const bookedMeetings = useAppSelector((state) =>
    selectBookedMeetingsInTheFuture(state)
  )
  const bookedMeetingsLoaded = useAppSelector(
    (state) => selectBookedMeetingsState(state).isSuccess
  )
  const bookedMeetingsLoading = useAppSelector(
    (state) => selectBookedMeetingsState(state).isLoading
  )
  const meetingSlots = useAppSelector((state) => selectAvailableSlots(state))

  const meetingsLoaded = useAppSelector(
    (state) => selectAvailableSlotsState(state).data !== undefined
  )
  const meetingsLoading = useAppSelector(
    (state) => selectAvailableSlotsState(state).isLoading ?? false
  )
  const latestBookedMeeting = useAppSelector((state) =>
    selectLatestBookedMeeting(state)
  )
  const meetingSlotsByLatestEmployee = useAppSelector((state) =>
    selectAvailableSlotsByLatestEmployee(state)
  )

  const getBookedMeetings = useCallback(() => {
    void triggerGetBookedMeetingsQuery()
  }, [triggerGetBookedMeetingsQuery])

  const getMeetingSlots = useCallback(() => {
    void triggerAvailableSlotsQuery()
  }, [triggerAvailableSlotsQuery])

  const sendMeetingBooking = useCallback(
    (meeting: IMeeting, description: string) => {
      void bookMeeting({
        timeslotId: meeting.id,
        meetingDescription: description,
        meetingLocationType: meeting.meetingLocationType ?? '',
      })
    },
    [bookMeeting]
  )

  const sendMeetingRebooking = useCallback(
    (meetingId: string, timeSlot: IMeeting, meetingLocationType?: string) => {
      void rebookMeeting({
        meetingId,
        timeSlotId: timeSlot.id,
        meetingLocationType: meetingLocationType ?? '',
      })
    },
    [rebookMeeting]
  )

  const sendMeetingCancellation = useCallback(
    (meetingId: string, reason: string) => {
      void cancelMeeting({ meetingId, reason })
    },
    [cancelMeeting]
  )

  const toggleConfirmationModal = useCallback(
    (isToggled: boolean) => {
      if (isToggled) {
        dispatch(showModal())
      } else {
        dispatch(closeModal())
      }
    },
    [dispatch]
  )

  // Advisor
  const [triggerGetAdvisorQuery] = advisorApi.useLazyGetAdvisorQuery()
  const getAdvisor = useCallback(() => {
    void triggerGetAdvisorQuery(sub)
  }, [triggerGetAdvisorQuery, sub])

  const advisorLoaded = useAppSelector(
    (state) => selectAdvisorState(state, sub).isSuccess
  )
  const advisorLoading = useAppSelector(
    (state) => selectAdvisorState(state, sub).isLoading
  )
  const advisor = useAppSelector((state) => selectAdvisor(state, sub))

  const isToggledConfirmationModal = useAppSelector(selectModal).modalOpen
  const meetingEventType = useAppSelector(
    selectMeetingEventType
  ).meetingEventType

  return {
    ...initialState,
    bookedMeetingsLoaded,
    bookedMeetingsLoading,
    bookedMeetings,
    meetingSlots,
    latestBookedMeeting,
    meetingSlotsByLatestEmployee,
    meetingsLoading,
    meetingsLoaded,
    isToggledConfirmationModal,
    meetingEventType,
    advisorLoaded,
    advisorLoading,
    advisor,

    getBookedMeetings,
    getMeetingSlots,
    sendMeetingBooking,
    sendMeetingRebooking,
    sendMeetingCancellation,
    toggleConfirmationModal,
    getAdvisor,
  }
}
