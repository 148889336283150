import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  colors,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { timespan, date } from '@trr/frontend-datetime'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import {
  Event,
  WatchLater,
  Phone,
  Videocam,
  Person,
  Info,
  OpenInNew,
  MeetingRoomOutlined,
} from '@mui/icons-material'
import { useTrackVirtualView } from '@trr/gtm-tracking'

interface IMeetingSummaryProps {
  meeting: IMeeting
  handleRebooking: (meeting: IMeeting) => void
  handleCancellation: (meeting: IMeeting, message: string) => void
}
const MeetingSummary = (
  {
    meeting,
    handleCancellation,
    handleRebooking
  }: React.PropsWithChildren<IMeetingSummaryProps>
) => {
  const [isCancellationExpanded, setIsCancellationExpanded] = useState(false)
  const [message, setMessage] = useState('')
  const trackVirtualView = useTrackVirtualView('meetings')

  const toggleExpanded = React.useCallback(() => {
    setIsCancellationExpanded(
      (previsCancellationExpanded) => !previsCancellationExpanded
    )
    // Track virtual page view in ga
    trackVirtualView('Avboka möte')
  }, [trackVirtualView])

  const handleTextChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(event.target.value)
    },
    [setMessage]
  )

  const content = useEpiContent<IContent>()

  interface MeetingType {
    icon: JSX.Element
    text: string
  }
  type MeetingTypes = Record<string, MeetingType>

  // FIX: Texter i Epi kanske?
  const meetingTypes: MeetingTypes = {
    unknown: {
      icon: <></>,
      text: '',
    },
    phone: {
      icon: <Phone data-testid="phone-icon" sx={{ color: colors.grey[700] }} />,
      text: 'Telefonmöte',
    },
    inPersonMeeting: {
      icon: <MeetingRoomOutlined data-testid="inperson-icon" />,
      text: 'Fysiskt möte',
    },
    onlineMeeting: {
      icon: (
        <Videocam
          data-testid="videocam-icon"
          sx={{ color: colors.grey[700] }}
        />
      ),
      text: 'Onlinemöte',
    },
  }

  const meetingTypeText =
    meetingTypes[meeting.meetingLocationType ?? 'unknown'].text
  const MeetingTypeIcon = () =>
    meetingTypes[meeting.meetingLocationType ?? 'unknown'].icon

  return (
    <Card
      className="MeetingSummary"
      variant="outlined"
      key={meeting.id}
      sx={{ padding: 3 }}
    >
      <Typography variant="h4" component="h3" sx={{ paddingBottom: 2 }}>
        {content.meetingSummaryHeading}
      </Typography>
      <Box>
        <List sx={{ display: { md: 'flex', order: 1 }, flexWrap: 'wrap' }}>
          <ListItem sx={{ flexBasis: '50%' }} disableGutters divider={false}>
            <ListItemIcon color="surface.blue" aria-label="Datum">
              <Event sx={{ color: colors.grey[700] }} />
            </ListItemIcon>
            <ListItemText>
              {date.format(new Date(meeting.startTime), {
                year: true,
              })}
            </ListItemText>
          </ListItem>

          <ListItem
            sx={{ flexBasis: '50%', order: 3 }}
            disableGutters
            divider={false}
          >
            <ListItemIcon aria-label="Tid">
              <WatchLater sx={{ color: colors.grey[700] }} />
            </ListItemIcon>
            <ListItemText>
              {`${timespan.format(meeting.startTime, meeting.endTime)}`}
            </ListItemText>
          </ListItem>

          <ListItem
            sx={{ flexBasis: '50%', order: 5 }}
            disableGutters
            divider={false}
          >
            <ListItemIcon aria-label="Mötestyp">
              <MeetingTypeIcon />
            </ListItemIcon>
            <ListItemText>{meetingTypeText}</ListItemText>
          </ListItem>
          <ListItem
            sx={{ flexBasis: '50%', order: 2 }}
            disableGutters
            divider={false}
          >
            <ListItemIcon aria-label="Rådgivare">
              <Person sx={{ color: colors.grey[700] }} />
            </ListItemIcon>
            <ListItemText>{meeting.employee.fullname}</ListItemText>
          </ListItem>
          <ListItem
            sx={{ flexBasis: '50%', order: 4 }}
            disableGutters
            divider={false}
          >
            <ListItemIcon aria-label="Bokat av">
              <Info sx={{ color: colors.grey[700] }} />
            </ListItemIcon>
            <ListItemText>
              {`Mötet bokades av ${
                meeting.bookedBy === 'attendee'
                  ? 'dig'
                  : meeting.employee.fullname
              }`}
            </ListItemText>
          </ListItem>
        </List>
      </Box>
      <Box sx={{ marginTop: 4 }}>
        {!isCancellationExpanded && (
          <Button
            variant="outlined"
            onClick={toggleExpanded}
            data-testid={'expand-cancel-meeting'}
            sx={{
              display: { sx: 'block' },
              marginBottom: { xs: 2, md: 0 },
              width: { xs: '100%', md: 'auto' },
            }}
          >
            {content.openMeetingCancellationButton}
          </Button>
        )}
        {meeting.bookedBy === 'attendee' && !isCancellationExpanded && (
          <Button
            onClick={() => handleRebooking(meeting)}
            data-testid={'rebooking-button'}
            sx={{
              width: { xs: '100%', md: 'auto' },
              marginLeft: { md: 2 },
              marginBottom: { xs: 2, md: 0 },
            }}
          >
            {content.rescheduleMeetingButtonText}
          </Button>
        )}
        {!isCancellationExpanded &&
          meeting.meetingLocationType === 'onlineMeeting' &&
          meeting.onlineMeetingJoinUrl && (
            <Button
              LinkComponent={'a'}
              sx={{
                border: 'none',
                width: { xs: '100%', md: 'auto' },
                paddingRight: { xs: 0, md: 4 },
                '&:focus': { textDecoration: 'underline' },
                '&:hover': { border: 'none', background: 'none' },
              }}
              disableTouchRipple
              href={meeting.onlineMeetingJoinUrl}
              variant="outlined"
              startIcon={<OpenInNew />}
              aria-label={`${content.connectToVideoMeetingButtonText} (öppnas i nytt fönster)`}
              target="_blank"
            >
              {content.connectToVideoMeetingButtonText}
            </Button>
          )}
        {isCancellationExpanded && (
          <div aria-live="polite">
            <Typography variant="body1" sx={{ paddingBottom: 2 }}>
              {content.cancelMeetingTooltip}
            </Typography>
            <TextField
              sx={{ width: '100%' }}
              label={content.textareaLabelMeetingCancellation}
              placeholder="Skriv här..."
              multiline
              onChange={handleTextChange}
              data-testid="cancellation-textarea"
              rows={4}
            />
            <Box sx={{ marginTop: 4 }}>
              <Button variant="outlined" onClick={toggleExpanded}>
                {content.abortCancelMeetingButtonText}
              </Button>
              <Button
                onClick={() => handleCancellation(meeting, message)}
                data-testid={'cancel-meeting-button'}
                sx={{ marginLeft: 2 }}
              >
                {content.cancelMeetingButtonText}
              </Button>
            </Box>
          </div>
        )}
      </Box>
    </Card>
  )
}
export default MeetingSummary
