import { Provider } from 'react-redux'
import {
  type AppShellDataProps,
  AppShellDataProvider,
} from '@trr/app-shell-data'
import { ThemeProvider, createTheme } from '@mui/material'
import { themeOptions, defaultLocale } from '@trr/mui-theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { sv } from 'date-fns/locale'
import { svSE } from '@mui/material/locale'
import { GTMTracker } from '@trr/gtm-tracking'

import Store from './Store/configureStore'
import BookMeeting from './Meetings/BookMeeting/BookMeeting'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale, svSE)

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <GTMTracker mfName="meetings">
          <AppShellDataProvider value={appShellData}>
            <Provider store={Store}>
              <BookMeeting />
            </Provider>
          </AppShellDataProvider>
        </GTMTracker>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
