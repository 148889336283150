import {
  PickersDay,
  pickersDayClasses,
  type PickersDayProps,
} from '@mui/x-date-pickers'

const PickersDayCustom = (props: PickersDayProps<Date>) => (
  <PickersDay
    {...props}
    data-testid={!props.disabled ? 'enabled' : ''}
    sx={{
      [`&&.${pickersDayClasses.selected}`]: {
        '&::after': {
          display: 'none',
        },
      },
      [`&&.${pickersDayClasses.root}`]: {
        '&::after': {
          backgroundColor: 'secondary.main',
          position: 'absolute',
          top: '26px',
          width: '6px',
          height: '6px',
          borderRadius: '6px',
          content: '""',
        },
      },
      [`&&.${pickersDayClasses.disabled}`]: {
        color: 'neutral.light',
        '&::after': {
          display: 'none',
        },
      },
    }}
  />
)

export default PickersDayCustom
