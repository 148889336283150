import { isAfter } from 'date-fns'
import { date as dateFormatter } from '@trr/frontend-datetime'
import { IMeeting } from 'Services/API/meetings/meetings.types'

export const getAvailableSlotsOnSpecificDate = (
  date: Date,
  dateList: IMeeting[]
): IMeeting[] | null => {
  try {
    return dateList.filter(
      (slot) =>
        dateFormatter.format(slot.startTime) === dateFormatter.format(date)
    )
  } catch (error) {
    return null
  }
}

export const getFirstDayWithAvailableSlots = (dateList: IMeeting[]): Date => {
  const upcomingSlots = dateList.filter((d) =>
    isAfter(new Date(d.startTime), new Date())
  )

  if (upcomingSlots.length === 0) {
    return new Date()
  }

  upcomingSlots.sort((a, b) =>
    new Date(a.startTime) > new Date(b.startTime) ? 1 : -1
  )
  return new Date(upcomingSlots[0].startTime)
}
