import { Box, List, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import PickMeetingType from 'Meetings/PickMeetingType'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import ChangeDateTime from 'Components/ChangeDateTime'

export interface IPickMeetingTypeStepProps {
  handleChangeMeetingType: (type: string) => void
  meetingType: string
  errorMessage?: string
  meeting: IMeeting
  handleSetStep: (step: number) => void
}
const PickMeetingTypeStep = ({
  handleChangeMeetingType,
  meetingType,
  errorMessage,
  meeting,
  handleSetStep,
}: React.PropsWithChildren<IPickMeetingTypeStepProps>) => {
  const content = useEpiContent<IContent>()
  return (
    <Box sx={{ marginBottom: 4 }}>
      <Typography component="h2" variant="h5" sx={{ marginBottom: 4 }}>
        {content.pickMeetingType}
      </Typography>
      <PickMeetingType
        setMeetingType={handleChangeMeetingType}
        meetingType={meetingType}
        errorMessage={errorMessage}
      />
      <List>
        <ChangeDateTime handleSetStep={handleSetStep} meeting={meeting} />
      </List>
    </Box>
  )
}

export default PickMeetingTypeStep
