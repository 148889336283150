import React, { useState, useCallback, useEffect } from 'react'
import { useMeetings } from 'Meetings/useMeetings'
import useIsMobileScreen from 'Utils/Hooks/useIsMobileScreen'
import { scrollToTop } from 'Utils/Helpers'
import { useRole } from 'Utils/Hooks/useRole'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import { Button } from '@mui/material'
import { useTrackCustomClick, useTrackVirtualView } from '@trr/gtm-tracking'
import usePrev from 'Utils/Hooks/usePrev'

import PickTimeStep from './BookMeetingSteps/PickTimeStep'
import PickMeetingTypeStep from './BookMeetingSteps/PickMeetingTypeStep'
import MeetingPurposeStep from './BookMeetingSteps/MeetingPurposeStep'
import StartStep from './BookMeetingSteps/StartStep'

const emptyMeeting: IMeeting = {
  startTime: '',
  endTime: '',
  id: '',
  employee: {
    id: '',
    fullname: '',
    userPrincipalName: '',
  },
  meetingLocationType: 'onlineMeeting',
}

export enum BookingSteps {
  default = 0,
  start,
  pickTime,
  pickMeetingType,
  meetingPurpose,
  makeBooking,
}

const HasNoBookingView = () => {
  const {
    goBackBookingStepButton,
    goForwardBookingStepButton,
    errorMessage: errorMessageContent,
    errorMissingDateTime,
    errorMeetingType,
  } = useEpiContent<IContent>()

  const {
    sendMeetingBooking,
    latestBookedMeeting,
    meetingSlotsByLatestEmployee,
  } = useMeetings()
  const isMobile = useIsMobileScreen()
  const [newMeeting, setNewMeeting] = useState(emptyMeeting)
  const [step, setStep] = useState(BookingSteps.default)
  const prevStep = usePrev(step)
  const [message, setMessage] = useState('')
  const [error, setError] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    meetingSlotsByLatestEmployee.length > 0
      ? latestBookedMeeting?.employee?.id
      : 'all'
  )
  const isTIA = useRole()
  const trackCustomClick = useTrackCustomClick()
  const trackVirtualView = useTrackVirtualView('meetings')

  const handleSetStep = (step: number) => {
    setStep(step)
  }

  // TODO: remove this ugly hack, we only need it now to be able to run the trackVirtualView for the startpage below
  useEffect(() => {
    setStep(1)
  }, [])

  useEffect(() => {
    // Track virtual page view in ga
    if (step !== prevStep && step !== BookingSteps.default) {
      trackVirtualView(BookingSteps[step])
    }
  }, [prevStep, step, trackVirtualView])

  const handleNextButtonClick = () => {
    setError(true)
    setErrorMessage('')

    if (step === 2 && !newMeeting?.startTime) {
      setErrorMessage(errorMissingDateTime)
      return setError(true)
    }
    if (step === 3 && !newMeeting?.meetingLocationType) {
      setErrorMessage(errorMeetingType)
      return setError(true)
    }

    handleSetStep(step + 1)

    // Track event in ga
    trackCustomClick('click-button', {
      label: `Nästa`,
    })
  }
  const handlePreviousButtonClick = () => {
    setErrorMessage('')
    setError(false)
    handleSetStep(step - 1)
    trackCustomClick('click-button', {
      label: `Tillbaka`,
    })
  }

  const handleClearMeeting = useCallback(() => {
    setNewMeeting((prev: IMeeting) => ({
      ...emptyMeeting,
      meetingLocationType: prev.meetingLocationType,
    }))
  }, [])

  const handleChangeTimeSlot = useCallback((slot: IMeeting) => {
    setNewMeeting((prev: IMeeting) => ({
      ...slot,
      meetingLocationType: prev.meetingLocationType,
    }))
  }, [])

  const handleChangeMeetingType = useCallback((type: string) => {
    setNewMeeting((prev: IMeeting) => ({ ...prev, meetingLocationType: type }))
  }, [])

  const handleChangeEmployee = useCallback((employeeId: string) => {
    setSelectedEmployeeId(employeeId)
  }, [])

  const handleBookMeetingButtonClick = React.useCallback(() => {
    if (isTIA && message === '') {
      setErrorMessage(errorMessageContent)
      return setError(true)
    }

    sendMeetingBooking(newMeeting, message)
    setNewMeeting(emptyMeeting)

    // Track in GA
    trackCustomClick('click-button', {
      label: `Boka möte ${message === '' ? 'utan syfte' : 'med syfte'}`,
    })
  }, [
    isTIA,
    message,
    sendMeetingBooking,
    newMeeting,
    trackCustomClick,
    errorMessageContent,
  ])

  useEffect(() => {
    if (isMobile) {
      scrollToTop()
    }
  }, [step, isMobile])

  return (
    <div className="HasNoBookingView">
      {step === BookingSteps.start && (
        <StartStep handleNextButtonClick={handleNextButtonClick} />
      )}

      {step === BookingSteps.pickTime && (
        <PickTimeStep
          meeting={newMeeting}
          employeeId={selectedEmployeeId}
          handleChangeEmployee={handleChangeEmployee}
          handleChangeTimeSlot={handleChangeTimeSlot}
          handleClearMeeting={handleClearMeeting}
          errorMessage={errorMessage}
        />
      )}

      {step === BookingSteps.pickMeetingType && (
        <PickMeetingTypeStep
          meeting={newMeeting}
          handleChangeMeetingType={handleChangeMeetingType}
          meetingType={newMeeting.meetingLocationType}
          errorMessage={errorMessage}
          handleSetStep={handleSetStep}
        />
      )}

      {step === BookingSteps.pickTime ||
      step === BookingSteps.pickMeetingType ? (
        <>
          <Button
            variant="outlined"
            onClick={() => handlePreviousButtonClick()}
          >
            {goBackBookingStepButton}
          </Button>
          <Button
            sx={{ marginLeft: 2 }}
            onClick={() => handleNextButtonClick()}
            data-testid={error ? 'next-button notValid' : 'next-button'}
          >
            {goForwardBookingStepButton}
          </Button>
        </>
      ) : null}

      {step === BookingSteps.meetingPurpose && (
        <MeetingPurposeStep
          meeting={newMeeting}
          message={message}
          handleSetStep={handleSetStep}
          handleSetMessage={setMessage}
          errorMessage={errorMessage}
          handleBookMeetingButtonClick={handleBookMeetingButtonClick}
          handlePreviousButtonClick={handlePreviousButtonClick}
        />
      )}
    </div>
  )
}

export default HasNoBookingView
