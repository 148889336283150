import { List, Typography } from '@mui/material'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import PickMeetingType from 'Meetings/PickMeetingType'
import ChangeDateTime from 'Components/ChangeDateTime'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'

interface IRebookMeetingProps {
  bookedMeeting: IMeeting
  newMeeting: IMeeting
  handleChangeMeetingType: (type: string) => void
  handleSetStep: (step: number) => void
}

const RebookMeetingPickMeetingType = ({
  bookedMeeting,
  newMeeting,
  handleChangeMeetingType,
  handleSetStep,
}: React.PropsWithChildren<IRebookMeetingProps>) => {
  const { pickMeetingType } = useEpiContent<IContent>()

  return (
    <>
      <Typography component="h2" variant="h5" sx={{ marginBottom: 4 }}>
        {pickMeetingType}
      </Typography>

      <PickMeetingType
        setMeetingType={handleChangeMeetingType}
        meetingType={
          newMeeting.meetingLocationType
            ? newMeeting.meetingLocationType
            : bookedMeeting.meetingLocationType
        }
        errorMessage={''}
      />

      <List sx={{ paddingBottom: 4 }}>
        <ChangeDateTime
          handleSetStep={handleSetStep}
          meeting={newMeeting.startTime ? newMeeting : bookedMeeting}
          divider={false}
        />
      </List>
    </>
  )
}

export default RebookMeetingPickMeetingType
