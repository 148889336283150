import {
  ListItem,
  Button,
  ListItemIcon,
  colors,
  ListItemText,
} from '@mui/material'
import { BookingSteps } from 'Meetings/BookMeetingView/HasNoBookingView/HasNoBookingView'

import { Videocam, Phone } from '@mui/icons-material'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'

interface ChangeMeetingTypeProps {
  handleSetStep: (step: number) => void
  meeting: IMeeting
}

const ChangeMeetingType = ({
  handleSetStep,
  meeting,
}: React.PropsWithChildren<ChangeMeetingTypeProps>) => {
  const {
    meetingTypeHeading,
    radioButtonPhoneLabel,
    radioButtonVideoLabel,
    changeBookingStepButton,
  } = useEpiContent<IContent>()
  return (
    <ListItem
      disableGutters
      divider={false}
      secondaryAction={
        <Button
          variant="text"
          aria-label={`${changeBookingStepButton} ${meetingTypeHeading}`}
          onClick={() => handleSetStep(BookingSteps.pickMeetingType)}
          data-testid="change-meeting-type-button"
        >
          {changeBookingStepButton}
        </Button>
      }
    >
      <ListItemIcon>
        {meeting.meetingLocationType === 'onlineMeeting' ? (
          <Videocam sx={{ color: colors.grey[700] }} />
        ) : (
          <Phone sx={{ color: colors.grey[700] }} />
        )}
      </ListItemIcon>
      <ListItemText
        primary={meetingTypeHeading}
        primaryTypographyProps={{ variant: 'body1' }}
        secondary={
          meeting.meetingLocationType === 'phone'
            ? radioButtonPhoneLabel
            : radioButtonVideoLabel
        }
        secondaryTypographyProps={{ variant: 'caption' }}
      />
    </ListItem>
  )
}

export default ChangeMeetingType
