import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { Typography, Alert } from '@mui/material'

const NoAvailableTimesView = () => {
  const { advisorHasNoAvailableTimesBody } = useEpiContent<IContent>()
  return (
    <Alert
      className="NoAvailableTimesView"
      severity="info"
      sx={{ color: 'neutral.main' }}
    >
      <Typography variant="body1">{advisorHasNoAvailableTimesBody}</Typography>
    </Alert>
  )
}

export default NoAvailableTimesView
