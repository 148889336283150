import { useEffect, useState } from 'react'
import { Alert, Box } from '@mui/material'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'

import { useErrorFeedback } from './useErrorFeedback'

const ErrorFeedback = () => {
  const {
    bookingStatus,
    rebookingStatus,
    cancellationStatus,
    meetingEventType,
  } = useErrorFeedback()

  const {
    systemFeedBackErrorBody,
    systemFeedbackErrorRebookingBody,
    systemFeedbackErrorCancellationBody,
  } = useEpiContent<IContent>()

  const [errorMessage, setErrorMessage] = useState('')

  const showFailedBooking =
    bookingStatus === QueryStatus.rejected && meetingEventType === 'booking'
  const showFailedRebooking =
    rebookingStatus === QueryStatus.rejected && meetingEventType === 'rebooking'
  const showFailedCancellation =
    cancellationStatus === QueryStatus.rejected &&
    meetingEventType === 'cancellation'

  useEffect(() => {
    if (showFailedBooking) {
      setErrorMessage(systemFeedBackErrorBody)
    } else if (showFailedRebooking) {
      setErrorMessage(systemFeedbackErrorRebookingBody)
    } else if (showFailedCancellation) {
      setErrorMessage(systemFeedbackErrorCancellationBody)
    } else {
      setErrorMessage('')
    }
  }, [
    showFailedBooking,
    showFailedCancellation,
    showFailedRebooking,
    systemFeedBackErrorBody,
    systemFeedbackErrorRebookingBody,
    systemFeedbackErrorCancellationBody,
  ])

  return (
    errorMessage !== '' && (
      <Box sx={{ paddingBottom: '20px' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Box>
    )
  )
}

export default ErrorFeedback
