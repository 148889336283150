import {
  selectBookMeetingStatus,
  selectCancelMeetingStatus,
  selectRebookMeetingStatus,
} from 'Services/API/meetings/meetings.selectors'
import { useMeetings } from 'Meetings/useMeetings'
import { useAppSelector } from 'Utils/Hooks/useAppSelector'
import { QueryStatus } from '@reduxjs/toolkit/query'

export interface IUseErrorFeedback {
  bookingStatus: QueryStatus
  rebookingStatus: QueryStatus
  cancellationStatus: QueryStatus
  meetingEventType: string
  isToggledConfirmationModal?: boolean
}

export const useErrorFeedback = (): IUseErrorFeedback => {
  const { meetingEventType } = useMeetings()
  const bookingStatus = useAppSelector(selectBookMeetingStatus)
  const cancellationStatus = useAppSelector(selectCancelMeetingStatus)
  const rebookingStatus = useAppSelector(selectRebookMeetingStatus)

  return {
    bookingStatus,
    cancellationStatus,
    rebookingStatus,
    meetingEventType,
  }
}
