import { IMeeting } from 'Services/API/meetings/meetings.types'

const getRebookReason = (bookedMeeting: IMeeting, newMeeting: IMeeting) => {
  if (
    newMeeting.startTime &&
    !newMeeting.meetingLocationType &&
    bookedMeeting.ownerId === newMeeting.ownerId
  )
    return 'med ändring av tid'

  if (!newMeeting.startTime && newMeeting.meetingLocationType)
    return 'med ändring av mötestyp'

  if (
    bookedMeeting.ownerId !== newMeeting.ownerId &&
    newMeeting.startTime &&
    newMeeting.meetingLocationType
  )
    return 'med ändring av rådgivare, tid och mötestyp'

  if (bookedMeeting.ownerId !== newMeeting.ownerId)
    return 'med ändring av rådgivare och tid'

  return 'med ändring av tid och mötestid'
}

export default getRebookReason
