import { useEffect } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useRole } from 'Utils/Hooks/useRole'
import { NoAdvisorAssignedView } from 'Meetings/BookMeetingView/NoAdvisorAssignedView/NoAdvisorAssignedView'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { advisorApi } from 'Services/API/advisor/advisor.api'

import BookMeetingView from '../BookMeetingView'
import ConfirmationModal from '../ConfirmationModal'
import CustomerSupport from '../CustomerSupport'
import ErrorFeedback from '../ErrorFeedback'
import { useMeetings } from '../useMeetings'

const BookMeeting = () => {
  const {
    getBookedMeetings,
    getMeetingSlots,
    meetingsLoaded,
    bookedMeetingsLoaded,
    getAdvisor,
    advisorLoaded,
    advisor,
  } = useMeetings()
  const { sub } = useAuthentication()
  const { heading } = useEpiContent<IContent>()
  const isTIA = useRole()
  const useGetAdvisorQueryStateResult =
    advisorApi.endpoints.getAdvisor.useQueryState(sub)

  useEffect(() => {
    if (!isTIA) getAdvisor()
  }, [getAdvisor, isTIA])

  useEffect(() => {
    if (isTIA || (advisorLoaded && !advisor.ingenTilldeladRadgivare)) {
      getBookedMeetings()
      getMeetingSlots()
    }
  }, [advisorLoaded, isTIA, getBookedMeetings, getMeetingSlots, advisor])

  // Set bool to true if user is a TIA or a TIO with an advisor
  const isTIAOrHasAdvisor = isTIA || !advisor?.ingenTilldeladRadgivare

  return (
    <Box sx={{ maxWidth: '752px' }}>
      <ErrorFeedback />
      <Typography variant="h1" gutterBottom>
        {heading}
      </Typography>
      {/* User is a TIA or a TIO WITH an advisor */}
      {isTIAOrHasAdvisor && bookedMeetingsLoaded && meetingsLoaded ? (
        <BookMeetingView />
      ) : !isTIAOrHasAdvisor || useGetAdvisorQueryStateResult.isError ? ( // User is a TIO WITHOUT an advisor
        <NoAdvisorAssignedView />
      ) : (
        <CircularProgress data-testid="loading-spinner" />
      )}
      <CustomerSupport />
      <ConfirmationModal />
    </Box>
  )
}

export default BookMeeting
