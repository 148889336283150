import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'Store/configureStore'

export interface ModalState {
  modalOpen: boolean
}

const initialState: ModalState = {
  modalOpen: false,
}

export const confirmationModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal: (state) => {
      state.modalOpen = false
    },
    showModal: (state) => {
      state.modalOpen = true
    },
  },
})

export const { showModal, closeModal } = confirmationModalSlice.actions

export const selectModal = (state: RootState) => state.confirmationModal

export default confirmationModalSlice.reducer
