import { useCallback, useEffect } from 'react'
import { Dialog, DialogTitle, IconButton, colors } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'

import { useErrorFeedback } from '../ErrorFeedback/useErrorFeedback'
import { useMeetings } from '../useMeetings'
import { scrollToTop } from '../../Utils/Helpers'

import { ConfirmationContent } from './ConfirmationContent'

const ConfirmationModal = () => {
  const { bookingStatus, cancellationStatus, rebookingStatus } =
    useErrorFeedback()

  const content = useEpiContent<IContent>()

  const {
    isToggledConfirmationModal,
    toggleConfirmationModal,
    meetingEventType,
  } = useMeetings()

  const handleCloseConfirmationModal = useCallback(() => {
    toggleConfirmationModal(false)
  }, [toggleConfirmationModal])

  useEffect(() => {
    if (isToggledConfirmationModal && meetingEventType === 'cancellation') {
      scrollToTop()
    }
  }, [isToggledConfirmationModal, meetingEventType])

  const getBookingContent = () => {
    switch (bookingStatus) {
      case 'fulfilled':
        return {
          title: content.confirmationModalHeading,
          body: content.confirmationModalBody,
          type: 'success',
        }
      case 'pending':
        return {
          title: content.processingBookingModalHeading,
          body: content.processingBookingModalBody,
          type: 'pending',
        }
      case 'rejected':
        return {
          title: content.errorBookingModalHeading,
          body: content.errorBookingModalBody,
          type: 'error',
        }
    }
  }

  const getRebookingContent = () => {
    switch (rebookingStatus) {
      case 'fulfilled':
        return {
          title: content.confirmationRescheduledMeetingModalHeading,
          body: content.confirmationRescheduledMeetingModalBody,
          type: 'success',
        }
      case 'pending':
        return {
          title: content.processingRescheduleMeetingModalHeading,
          body: content.processingRescheduleMeetingBody,
          type: 'pending',
        }
      case 'rejected':
        return {
          title: content.errorBookingModalHeading,
          body: content.errorRescheduleMeetingBody,
          type: 'error',
        }
    }
  }

  const getCancellationContent = () => {
    switch (cancellationStatus) {
      case 'fulfilled':
        return {
          title: content.confirmationCancelledMeetingModalHeading,
          body: content.confirmationCancelledMeetingModalBody,
          type: 'success',
        }
      case 'pending':
        return {
          title: content.processingCancelMeetingModalHeading,
          body: content.processingCancelMeetingBody,
          type: 'pending',
        }
      case 'rejected':
        return {
          title: content.errorBookingModalHeading,
          body: content.errorCancelMeetingBody,
          type: 'error',
        }
    }
  }

  const getModalContent = () => {
    switch (meetingEventType) {
      case 'booking':
        return getBookingContent()
      case 'rebooking':
        return getRebookingContent()
      case 'cancellation':
        return getCancellationContent()
    }
  }

  return (
    isToggledConfirmationModal && (
      <Dialog
        className="ConfirmationModal"
        onClose={handleCloseConfirmationModal}
        open={isToggledConfirmationModal}
      >
        <DialogTitle sx={{ m: 0, p: 5 }}>
          <IconButton
            aria-label={content.closeWindowButtonText}
            onClick={handleCloseConfirmationModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[800],
            }}
          >
            <Close sx={{ color: colors.grey[700] }} />
          </IconButton>
        </DialogTitle>
        <ConfirmationContent
          title={getModalContent()?.title}
          text={getModalContent()?.body}
          buttonText={content.closeWindowButtonText}
          testSelector={meetingEventType + '-' + getModalContent()?.type}
          contentType={getModalContent()?.type}
          handleCloseModal={handleCloseConfirmationModal}
        />
      </Dialog>
    )
  )
}

export default ConfirmationModal
