import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'Store/configureStore'

import { advisorApi } from './advisor.api'

export const selectAdvisorState = createSelector(
  (state: RootState, sub: string) =>
    advisorApi.endpoints.getAdvisor.select(sub)(state),
  (advisorState) => advisorState
)

export const selectAdvisor = createSelector(
  selectAdvisorState,
  (advisorState) => advisorState.data ?? null
)
