import { showModal } from 'Meetings/ConfirmationModal/confirmationModalSlice'
import { setMeetingEventType } from 'Meetings/meetingEventTypeSlice'

import { baseApi } from '../baseApi'

import {
  IBookedMeetingsResponse,
  IAvailableTimeSlotsResponse,
  IBookMeetingQuery,
  ICancelMeetingResponse,
  ICancelMeetingQuery,
  IRebookMeetingResponse,
  IRebookMeetingQuery,
} from './meetings.types'

export const meetingsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getBookedMeetings: builder.query<IBookedMeetingsResponse, void>({
      query: () => ({
        url: `meetings/v2.1/meetings`,
      }),
      providesTags: ['Meeting'],
    }),
    getAvailableSlots: builder.query<IAvailableTimeSlotsResponse, void>({
      query: () => `meetings/v2.1/timeslots`,
      providesTags: ['MeetingSlot'],
    }),
    cancelMeeting: builder.mutation<
      ICancelMeetingResponse,
      ICancelMeetingQuery
    >({
      query: (data) => {
        const { meetingId, ...body } = data
        return {
          url: `meetings/v2.1/meetings/${meetingId}`,
          method: 'DELETE',
          body,
        }
      },
      onQueryStarted: (_, { dispatch }) => {
        dispatch(showModal())
        dispatch(setMeetingEventType('cancellation'))
      },
      invalidatesTags: ['Meeting'],
    }),
    bookMeeting: builder.mutation<IBookedMeetingsResponse, IBookMeetingQuery>({
      query: (data) => {
        const { ...body } = data
        return {
          url: `meetings/v2.1/meetings`,
          method: 'POST',
          body,
        }
      },
      onQueryStarted: (_, { dispatch }) => {
        dispatch(showModal())
        dispatch(setMeetingEventType('booking'))
      },
      invalidatesTags: ['Meeting', 'MeetingSlot'],
    }),
    rebookMeeting: builder.mutation<
      IRebookMeetingResponse,
      IRebookMeetingQuery
    >({
      query: (data) => {
        const { meetingId, timeSlotId, meetingLocationType } = data
        return {
          url: `/meetings/v2.1/meetings/${meetingId}`,
          method: 'PATCH',
          body: { timeSlotId, meetingLocationType },
        }
      },
      onQueryStarted: (_, { dispatch }) => {
        dispatch(showModal())
        dispatch(setMeetingEventType('rebooking'))
      },
      invalidatesTags: ['Meeting', 'MeetingSlot'],
    }),
  }),
})

export const cacheKeys = {
  bookMeeting: 'bookMeeting',
  cancelMeeting: 'cancelMeeting',
  rebookMeeting: 'rebookMeeting',
}
