import { Button, List, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import ChangeDateTime from 'Components/ChangeDateTime'
import ChangeMeetingType from 'Components/ChangeMeetingType'
import MeetingPurpose from 'Meetings/MeetingPurpose'
import { IMeeting } from 'Services/API/meetings/meetings.types'

export interface IMeetingPurposeStepProps {
  message: string
  handleSetMessage: (message: string) => void
  errorMessage: string
  meeting: IMeeting
  handleSetStep: (step: number) => void
  handleBookMeetingButtonClick: () => void
  handlePreviousButtonClick: () => void
}
const MeetingPurposeStep = ({
  meeting,
  message,
  handleSetMessage,
  errorMessage,
  handleSetStep,
  handleBookMeetingButtonClick,
  handlePreviousButtonClick,
}: React.PropsWithChildren<IMeetingPurposeStepProps>) => {
  const { meetingPurpose, goBackBookingStepButton, sendBookingButtonText } =
    useEpiContent<IContent>()

  return (
    <>
      <Typography component="h2" variant="body1" fontWeight={'bold'}>
        {meetingPurpose}
      </Typography>
      <MeetingPurpose
        message={message}
        handleSetMessage={handleSetMessage}
        errorMessage={errorMessage}
      />
      <List sx={{ paddingBottom: 4 }}>
        <ChangeDateTime
          handleSetStep={handleSetStep}
          meeting={meeting}
          divider
        />
        <ChangeMeetingType handleSetStep={handleSetStep} meeting={meeting} />
      </List>
      <Button onClick={() => handlePreviousButtonClick()} variant="outlined">
        {goBackBookingStepButton}
      </Button>

      <Button
        onClick={handleBookMeetingButtonClick}
        data-testid={'send-booking-button'}
        sx={{ marginLeft: 2 }}
      >
        {sendBookingButtonText}
      </Button>
    </>
  )
}

export default MeetingPurposeStep
