import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAppConfig } from '@trr/app-shell-data'

import { prepareHeaders } from './helpers/prepareHeaders'

const {
  COMMON: { KLIENT_API_URL },
} = getAppConfig()

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${KLIENT_API_URL}/`,
    prepareHeaders,
  }),
  tagTypes: ['Meeting', 'MeetingSlot', 'Advisor'],
  endpoints: () => ({}),
})
