import { BookingSteps } from 'Meetings/BookMeetingView/HasNoBookingView/HasNoBookingView'
import { useRef, useEffect } from 'react'

const usePrev = (value: BookingSteps) => {
  const ref = useRef<BookingSteps>(BookingSteps.start)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export default usePrev
