import { QueryStatus } from '@reduxjs/toolkit/query'

import { useMeetings } from '../useMeetings'
import { useErrorFeedback } from '../ErrorFeedback/useErrorFeedback'

import NoAvailableTimesView from './NoAvailableTimesView'
import HasNoBookingView from './HasNoBookingView'
import HasBookingView from './HasBookingView'

const BookMeetingView = () => {
  const { bookedMeetings, meetingSlots } = useMeetings()

  const { bookingStatus, cancellationStatus, rebookingStatus } =
    useErrorFeedback()

  const statuses = [bookingStatus, rebookingStatus, cancellationStatus]
  const isPending = statuses.includes(QueryStatus.pending)
  const hasBooking = bookedMeetings.length > 0
  const hasMeetingSlots = meetingSlots.length > 0

  return (
    <div>
      {hasBooking || isPending ? <HasBookingView /> : null}
      {hasMeetingSlots && !hasBooking && !isPending && <HasNoBookingView />}
      {!hasMeetingSlots && !hasBooking && <NoAvailableTimesView />}
    </div>
  )
}

export default BookMeetingView
